// .brand-rebate-main {
//   .image-gallery {
//     height: 100%;
//     width: 1050px;
//     background-color: black;
//     overflow: hidden;
//     display: flex;
//   }
// }
.brand-rebate-main {
  .image-gallery {
   margin: auto;
    height: 100%;

  }
}